<template>
    <div id="loading-wms-loading-container-base">
        <div id="loading-wms-loading-overlay">
            <div id="loading-wms-loading-center-title-container-aguarde">
                <div id="loading-wms-loading-center-title-aguarde">Aguarde...</div>
            </div>
            <div id="loading-wms-loading-center-spinner">
                <div id="loading-wms-spinner">
                    <div id="loading-wms-double-bounce1"></div>
                    <div id="loading-wms-double-bounce2"></div>
                </div>
            </div>
            <div id="loading-wms-loading-title-container">
                <div id="loading-wms-loading-title-linxmicrovix">Linx Microvix</div>
                <div id="loading-wms-loading-title-wms">{{ nomeAplicacao }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        nomeAplicacao() {
            return process.env.VUE_APP_NOME_APLICACAO;
        }
    }
};
</script>

<style scoped>
#loading-wms-loading-container-base {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100%;
    z-index: 99999;
}
#loading-wms-loading-center-title-container-aguarde {
    position: relative;
    top: 35%;
    text-align: center;
}
#loading-wms-loading-center-title-aguarde {
    color: #fff;
    font-family: Dosis, sans-serif;
    font-weight: bold;
    font-size: 2rem;
}
#loading-wms-loading-overlay {
    margin: 0 auto;
    display: flex;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.8);
    overflow: hidden;
}
#loading-wms-loading-center-spinner {
    position: relative;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -40%);
    height: 60px;
    width: 60px;
}
#loading-wms-loading-title-container {
    position: relative;
    top: 40%;
}
#loading-wms-loading-title-linxmicrovix {
    text-align: center;
    font-family: Dosis, sans-serif;
    font-size: 2rem;
    color: #ffb200;
    height: 35px;
}
#loading-wms-loading-title-wms {
    text-align: center;
    font-family: Dosis, sans-serif;
    font-weight: bold;
    font-size: 2rem;
    color: #fff;
}
#loading-wms-spinner {
    width: 80px;
    height: 80px;
}
#loading-wms-double-bounce1,
#loading-wms-double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #333;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
}
#loading-wms-double-bounce1 {
    background-color: #ffb200;
}
#loading-wms-double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
    background-color: #fff;
}
@-webkit-keyframes sk-bounce {
    0%,
    100% {
        -webkit-transform: scale(0);
    }
    50% {
        -webkit-transform: scale(1);
    }
}
@keyframes sk-bounce {
    0%,
    100% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }
    50% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}
</style>
