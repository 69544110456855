<template>
    <section class="layout-container-app">
        <loading-component v-if="isLoading" />
        <div class="layout-container-header">
            <nav-bar />
            <menu-lateral />
        </div>
        <div class="layout-container-body gray-scroll" id="card-principal">
            <router-view />
            <div class="col-12 bg-primary mt-2 fixed-bottom" style="height: 4px"></div>
        </div>
    </section>
</template>

<script>
import NavBar from '@/components/navbar.vue';
import MenuLateral from '@/components/menu.vue';
import LoadingComponent from '@/components/base-loading.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'Layout',

    components: {
        NavBar,
        MenuLateral,
        LoadingComponent
    },
    computed: {
        ...mapGetters({
            isLoading: 'uiState/isLoading'
        })
    },
    created() {
        this.$router.push({ name: 'home' });
    }
};
</script>

<style scoped>
.layout-container-app {
    margin: 0 auto;
    display: flex;
    height: 100vh;
    flex-direction: column;
}

.layout-container-header {
    height: fit-content;
}

.layout-container-body {
    flex-grow: 1;
    overflow: auto;
}
</style>
