<template>
    <div id="lmxta-menu-btn-produtos" class="card shadow-sm shadow-hover my-1" @click="abrirTela(rota)">
        <div class="card-header p-0">
            <div
                :class="{
                    'row menu-item': true,
                    'menu-item-atual ': verificarSeTelaEstaNaRotaAtual(rota)
                }"
            >
                <div class="col-12 d-flex align-items-center ml-2">
                    <i :class="icone"></i>
                    <h6 class="ml-4 mt-1">{{ descricao }}</h6>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import swal from '@/common/alerts';
import { mapGetters } from 'vuex';

export default {
    name: 'ItemMenu',
    props: {
        descricao: null,
        icone: null,
        rota: null
    },
    computed: {
        ...mapGetters({
            urlAjuda: 'appData/urlGuiaRapidoWMS'
        })
    },
    methods: {
        verificarSeTelaEstaNaRotaAtual(nomeRota) {
            return this.$route.matched.filter((r) => {
                return r.name === nomeRota;
            }).length;
        },
        async abrirTela(tela) {
            const navegarParaTela = async () => {
                let estaNaRotaAtual = this.verificarSeTelaEstaNaRotaAtual(tela);
                if (!estaNaRotaAtual) {
                    await this.$store.dispatch('uiState/toggleMenu');
                    this.$router.push({ name: tela });
                }
            };

            if (tela === 'logout') {
                swal.exibirPergunta('Sair', 'Tem certeza que deseja sair?', (resposta) => {
                    if (resposta) {
                        navegarParaTela();
                    }
                });
            } else if (tela === 'ajuda') {
                window.open(this.urlAjuda, '_blank');
                return;
            } else {
                navegarParaTela();
                return;
            }
        }
    }
};
</script>

<style scoped>
.menu-item-atual {
    background-color: #ffb200 !important;
    cursor: unset !important;
    color: #5b2e90 !important;
}
.menu-item-atual:hover {
    background-color: #ffb200 !important;
    cursor: unset !important;
    color: #5b2e90 !important;
}
.menu-item {
    cursor: pointer;
}

@media (hover: hover) and (pointer: fine) {
    .menu-item:hover {
        background-color: #ffb200;
        transition: 0.3s all;
    }
}
</style>
