<template>
    <section id="navBar" class="d-flex w-auto m-0 bg-primary">
        <div class="row w-100 ml-0">
            <div class="col-12 d-flex justify-content-between align-items-center mt-2 px-2">
                <a class="cursor-pointer nav-link text-white p-0 mb-0 ml-2" @click="toggleMenu()" title="Menu" id="lmxta-navbar-btn-abrirmenu">
                    <i class="fas fa-bars fa-lg"></i>
                </a>
                <div class="d-flex flex-column justify-content-between align-items-center">
                    <h6 class="title text-center w-100 mb-0 text-uppercase">
                        <strong class="text-white mr-1"
                            >WMS

                            <i :class="['ml-3 ', isMobile ? 'fas fa-mobile' : 'fas fa-desktop']"></i>
                        </strong>
                    </h6>
                    <span class="menu-title"> {{ tituloPagina }}</span>
                </div>
                <!-- Logo -->
                <div>
                    <picture class="center-align logotipo" style="display: block">
                        <img class="lx-icon-md" src="../assets/Images/logo_linx_branco.png" alt="Logo Linx" />
                        <h2 class="text-brand-product text-uppercase mt-1" title="Microvix">
                            Microvix
                        </h2>
                    </picture>
                </div>
            </div>
            <div class="col-12 bg-secondary" style="height: 3px"></div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'NavBar',
    data() {
        return {
            storage: window.localStorage
        };
    },
    computed: {
        ...mapGetters({
            isMobile: 'appData/ehWMSMobile',
            preferenciasUsuario: 'appData/preferenciasUsuario',
            tituloPagina: 'uiState/tituloPagina'
        })
    },
    created() {
        this.storage = window.localStorage;
    },
    methods: {
        toggleMenu() {
            this.$store.dispatch('uiState/toggleMenu');
        }
    }
};
</script>

<style scoped>
.tooltip-preferencias {
    position: relative;
    display: inline-block;
}

.tooltip-preferencias .tooltiptext {
    visibility: hidden;
    width: max-content;
    background-color: rgba(91, 46, 144, 1);
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    left: 150%;
}

.tooltip-preferencias .tooltiptext::after {
    content: '';
    position: absolute;
    top: 7%;
    right: 100%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent rgba(91, 46, 144, 0.9) transparent transparent;
}

.tooltip-preferencias:hover .tooltiptext {
    visibility: visible;
}

.menu-title {
    position: relative;
    text-align: center;
    font-family: Dosis, sans-serif;
    font-size: 0.875rem;
    color: #ffb200;
}

.text-brand-product {
    color: #fff;
    font-size: 0.5rem;
    font-weight: 300;
}
</style>
